import { combineReducers } from 'redux';
import auth from "./auth";
import {chatTopics} from './chattopics';
import {error} from "./error"
import websock from "./websock"
import account_default from './account_default';

import {
  LOGOUT_SUCCESS,
} from "../constants/actions";

const appReducer = combineReducers({
  auth,
  chatTopics,
  error,
  websock,
  account_default,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
