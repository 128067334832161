import React, { useState, useRef, useEffect } from "react";
// import { Navigate } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  CLEAR_ALL,
  LOGIN_REQUEST,
  CLEAR_ERRORS,

} from "../constants/actions";

import { ValidateEmail } from "../utilities/Validate";
import OrgList from "./OrgList";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [orgList, setOrgList] = useState([]);

  const [userAuth, setUserAuth] = useState(false);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [showOrgList, setShowOrgList] = useState(false);

  const [currOrgId, setCurrOrgId] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(false);

  const { get_auth, get_account_default } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_account_default: state.account_default,
    }),
    shallowEqual
  );

  useEffect(() => {
    setUserAuth(get_auth);
    // if (get_auth?.next_steps === 1) {
    setOrgList(get_auth?.organization_list);
    setCurrOrgId(Number(get_auth?.organization_id))
    // }
  }, [get_auth]);

  useEffect(() => {
    setSelectedOrgId(get_account_default?.organization_selected)
  }, [get_account_default])


  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (password?.length === 0) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter a password",
      };
      totalErrors += 1;
    }
    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email",
        };
        totalErrors += 1;
      }
    }
    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [password, email, pristine]);

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    dispatch({
      type: CLEAR_ALL,
    });
    dispatch({
      type: CLEAR_ERRORS,
    });

    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: email,
        password: password,
      },
    });
    // setPristine(true);
  };

  useEffect(() => {
    if (userAuth?.token) {
      if (orgList?.length > 1 && !selectedOrgId) {
        setShowOrgList(true);
        return
      }
      navigate('/apps')
    }
  }, [userAuth, orgList, selectedOrgId, navigate]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.target === usernameRef.current) {
        passwordRef.current.focus();
      } else if (e.target === passwordRef.current) {
        handleLogin();
      }
    }
  };

  const handleLogin = () => {
    // Implement your login logic here
    // console.log("Login button clicked");
    onSubmit();
  };

  return (
    <Container>
      {showOrgList  ? (
        <OrgList get_auth={get_auth}  options={orgList} currOrgId={currOrgId} />
      ) : (
        <LoginForm>
          <Title>Login</Title>
          <Input
            type="text"
            placeholder="Email"
            value={email}
            ref={usernameRef}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            ref={passwordRef}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <LoginButton onClick={handleLogin}>Login</LoginButton>
        </LoginForm>
      )}
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const LoginForm = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
