import {
    UPDATE_ACCOUNT_DEFAULT_PENDING,
    LOGIN_UPDATE_ACCOUNT_DEFAULT,
    CLEAR_UPDATE_ACCOUNT_DEFAULT,
    UPDATE_ACCOUNT_DEFAULT_ORGANIZATION,

} from "../constants/actions";;

const initialState = {
    token: false,
    key: false,
    is_authenticated: false,
    user_id: false,
    role_id: false,
    organization_id: false,
    employee_id: false,
    ambassador_id: false,
    expires_in: false,
    is_staff: false,
    message: false,
    error: false,
    next_steps: 0,
    access_token_expires_at: false,
    refresh_token_expires_at: false,
    session_id: false,
    url: false,
    isLoading: false,
    organization_list: [],
    user: [],
    employee: [],
    organization: [],
    registered: [],
    forgot_password: [],
    reset_password: [],
    changed_password: [],
    register_org: [],
    register_token: [],
    organization_selected: false,

};


export default function AccountDefault(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACCOUNT_DEFAULT_PENDING:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_UPDATE_ACCOUNT_DEFAULT:
            return {
                ...state,
                ...action.payload,
            };

        case CLEAR_UPDATE_ACCOUNT_DEFAULT:
            return {
                ...state,
                token: false,
                key: false,
                is_authenticated: false,
                user_id: false,
                role_id: false,
                organization_id: false,
                employee_id: false,
                ambassador_id: false,
                expires_in: false,
                is_staff: false,
                message: false,
                error: false,
                next_steps: 0,
                access_token_expires_at: false,
                refresh_token_expires_at: false,
                session_id: false,
                url: false,
                isLoading: false,
                organization_list: [],
                user: [],
                employee: [],
                organization: [],
                registered: [],
                forgot_password: [],
                reset_password: [],
                changed_password: [],
                register_org: [],
                register_token: [],
            };
        case UPDATE_ACCOUNT_DEFAULT_ORGANIZATION:
           return {
                ...state,
                organization_selected: action.payload,
            }

        default:
            return state;
    }
}