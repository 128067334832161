import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  LOGIN_PENDING,
  UPDATE_ACCOUNT_DEFAULT_PENDING,
  LOGIN_SUCCESS,
  LOGIN_UPDATE_ACCOUNT_DEFAULT,
  SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
  ALL_ERRORS,
} from "../constants/actions";

import * as api from "../constants/api";


// LOGIN USER
function* defaultAccountUpdate(action) {
  yield put({ type: LOGIN_PENDING });
  yield put({ type: UPDATE_ACCOUNT_DEFAULT_PENDING });
  try {
    const login = yield call(api.UPDATE_ACCOUNT_DEFAULT, action.payload);
    yield put({ type: LOGIN_UPDATE_ACCOUNT_DEFAULT, payload: login.data });
    yield put({ type: LOGIN_SUCCESS, payload: login.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateDefaultAccount() {
  yield takeLatest(SG_LOGIN_UPDATE_ACCOUNT_DEFAULT, defaultAccountUpdate);
}

export default function* index() {
    yield all([
      updateDefaultAccount(),
    ]);
  }
  