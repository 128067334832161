// reducers.js

import { 
  ERROR_WEBSOCKET, 
  PENDING_WEBSOCKET,
  SET_WEB_CHAT_ID,
  SET_WEB_CHAT_MESSAGES,
  CONNECT_WEBSOCKET, 
  MESSAGE_WEBSOCKET, 
  DISCONNECT_WEBSOCKET 
} from "../constants/actions"

const initialState = {
  ws: false,
  isConnected: false,
  data: {},
  channel: {},
  message: {},
  error: false,
  pending: false
};

const webSocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_WEBSOCKET:
      return {
        ...state,
        pending: true,
      };

    case CONNECT_WEBSOCKET:
      return {
        ...state,
        isConnected: true,
        pending: false,
        ws: action.payload,
      };
  case SET_WEB_CHAT_ID:
    return {
      ...state,
      channel: { ...action.payload}
    };
    case SET_WEB_CHAT_MESSAGES:
      const Messages = action.payload.reduce((result, item) => {
        return {
          ...result,
          [item.id]: {
            ...item,
            server: {
              response: item.server.response,
              error: item.server.error
            },
          }
        };
      }, {});
      return {
        ...state,
        message: {...Messages},
        pending: false,
      };
    case MESSAGE_WEBSOCKET:
      return {
        ...state,
        data: action.payload,
        message: {
          ...state.message,
          [action.payload.id]: action.payload,
        },
        channel: { 
          id: action.payload.id, 
          name: action.payload.name
        },
        pending: false,
      };
    case ERROR_WEBSOCKET:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };

    case DISCONNECT_WEBSOCKET:
      // this is one of places where 
      // we know if the app's disconnected
      return {
        ...state,
        isConnected: false,
        pending: false,
        ws: false,
        data: {},
        channel: {},
        message: {},
      };

    default:
      return state;
  }
};

export default webSocketReducer;
