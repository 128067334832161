// actions.js
export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL = "CLEAR_ALL";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const SHOW_ERRORS = "SHOW_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SG_CLEAR_ERRORS = "SG_CLEAR_ERRORS";
export const SG_CLEAR_ALL = "SG_CLEAR_ALL";
//
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
//
export const UPDATE_ACCOUNT_DEFAULT_PENDING = "UPDATE_ACCOUNT_DEFAULT_PENDING";
export const SG_LOGIN_UPDATE_ACCOUNT_DEFAULT = "SG_LOGIN_UPDATE_ACCOUNT_DEFAULT"
export const LOGIN_UPDATE_ACCOUNT_DEFAULT = "LOGIN_UPDATE_ACCOUNT_DEFAULT";
export const UPDATE_ACCOUNT_DEFAULT_ORGANIZATION = "UPDATE_ACCOUNT_DEFAULT_ORGANIZATION"
export const CLEAR_UPDATE_ACCOUNT_DEFAULT = "CLEAR_UPDATE_ACCOUNT_DEFAULT"
//
export const CLEAR_PASSWORD_DATA = "CLEAR_PASSWORD_DATA";
//
export const SET_AUTH_ORGANIZATION = "SET_AUTH_ORGANIZATION";
//


//
export const ERROR = "ERROR"
export const FETCH_FAILURE = "FETCH_FAILURE"
export const FETCH_SUCCESS = "FETCH_SUCCESS"
export const CLEAR_ERROR = "CLEAR_ERROR"
//
export const ROOM_LOADING = "ROOM_LOADING"
export const SG_GET_CHAT_ROOM = "SG_GET_CHAT_ROOM"
export const GET_CHAT_ROOM = "GET_CHAT_ROOM"
export const SG_FETCH_CHAT_ROOM = "SG_FETCH_CHAT_ROOM"
export const FETCH_CHAT_ROOM = "FETCH_CHAT_ROOM"
export const SG_CREATE_CHAT_ROOM = "SG_CREATE_CHAT_ROOM"
export const CREATE_CHAT_ROOM = "CREATE_CHAT_ROOM"
export const SET_WEB_CHAT_ID = "SET_WEB_CHAT_ID"
export const SET_WEB_CHAT_MESSAGES = "SET_WEB_CHAT_MESSAGES"
export const DELETE_CHAT_ROOM = "DELETE_CHAT_ROOM"
export const SG_DELETE_CHAT_ROOM = "SG_DELETE_CHAT_ROOM"
export const CLEAR_DELETE_MESSAGE = "CLEAR_DELETE_MESSAGE";
//
export const ERROR_WEBSOCKET = "ERROR_WEBSOCKET"
export const PENDING_WEBSOCKET = "PENDING_WEBSOCKET"
export const CONNECT_WEBSOCKET = "CONNECT_WEBSOCKET"
export const SAGA_CONNECT_WEBSOCKET = "SAGA_CONNECT_WEBSOCKET"
export const MESSAGE_WEBSOCKET = "MESSAGE_WEBSOCKET"
export const SAGA_MESSAGE_WEBSOCKET = "SAGA_MESSAGE_WEBSOCKET"
//
export const SAGA_START_AND_MESSAGE_WEBSOCKET = "SAGA_START_AND_MESSAGE_WEBSOCKET"
//
export const DISCONNECT_WEBSOCKET = "DISCONNECT_WEBSOCKET"
export const SAGA_DISCONNECT_WEBSOCKET = "SAGA_DISCONNECT_WEBSOCKET"
//
// export const fetchDataRooms = payload => ({
//     type: FETCH_CHAT_ROOM,
//     payload,
//   });
  
//   export const createChatRoom = payload => ({
//     type: CREATE_CHAT_ROOM,
//     payload,
//   });

//   export const fetchDataSuccess = data => ({
//     type: FETCH_SUCCESS,
//     payload: data,
//   });
  
//   export const fetchFailure = error => ({
//     type: FETCH_FAILURE,
//     payload: error,
//   });
  