
import Webchat from '../webchat';

export const Menulist = [

    {
      url: "/apps/webchat",
      path: "webchat",
      name: "Webchat",
      element: <Webchat />,
      title: "AI Webchat",
      subtitle: "AI Webchat",
      visible: true,
      description: `AI Webchat for pushing the contents of survey responses to server and using that information to chat with AI.
      On creating of new chat the api call you can send the initial data as an object called contextData.
      This can be done only once when starting the chat, once you start the chat it can be resumed any time by clicking on the left which would have name of the chat
      The chat is authenticated using chat_token object in auth
      `
    },
]