// reducers.js
import {
  ROOM_LOADING, 
  CREATE_CHAT_ROOM, 
  GET_CHAT_ROOM, 
  FETCH_CHAT_ROOM, 
  FETCH_SUCCESS, 
  DELETE_CHAT_ROOM,
  CLEAR_DELETE_MESSAGE,
} from "../constants/actions"

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    deleted: null,
    chat_topics: []
  };
  
  export function chatTopics(state = initialState, action) {
    switch (action.type) {
      case ROOM_LOADING:
        return { 
          ...state, 
          isLoading: true };
    case GET_CHAT_ROOM:
            return {
               ...state, 
               ...action.payload,
               isLoading: false,
                error: null };
      case CREATE_CHAT_ROOM:
        return { 
          ...state , 
          created: action.payload,
          chat_topics: [...state.chat_topics, action.payload.data],
          isLoading: false, 
          error: action.payload.error };
      case FETCH_CHAT_ROOM:
          return {
             ...state, 
             [action.payload.id] : action.payload,
             isLoading: false,
              error: null };

      case DELETE_CHAT_ROOM:
        return { ...state, isLoading: false, deleted: action.payload };
      case CLEAR_DELETE_MESSAGE:
        return { ...state, isLoading: false, deleted: null };
      case FETCH_SUCCESS:
        return { ...state, isLoading: false, data: action.payload };

      default:
        return state;
    }
  }
  