import { all, fork } from 'redux-saga/effects';

import Auth from "./Auth";
import mySaga from './mySaga';
import websock from "./websock"
import AccountDefault from './AccountDefault';

export function* rootSaga() {
    yield all([
        fork(Auth),
        fork(mySaga),
        fork(websock),
        fork(AccountDefault),
    ])
}

export default rootSaga