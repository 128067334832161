// api.js
import axios from 'axios';
import urlconfig from "../_config";

// // const API_SERVER = ''
const API_SERVER = urlconfig.chatApi
const WS_SERVER = urlconfig.wsApi

// const TOKEN = 'osowcnormp'
// pass chat token
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const LOGIN_USER = (payload) => {
  const response = axios.post(`${urlconfig.loginapi}/sign-in`, payload, config);
  return response;
};


export const LOGOUT_USER = (payload) => {
  axios.post(`${urlconfig.loginapi}/logout`, payload, tokenConfig());
};

export async function UPDATE_ACCOUNT_DEFAULT(payload) {
  // console.log(data)
  return await axios.post(
    `${urlconfig.loginapi}/change-account-default`,
    payload,
    tokenConfig()
  );
}

const tokenConfig = () => {
  // Headers
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

const chatTokenConfig = () => {
  const chatToken = localStorage.getItem("chat_token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If chatToken, add to headers config
  if (chatToken) {
    config.headers["Authorization"] = `Bearer ${chatToken}`;
  }
  return config;
};

// these routes need chatToken
export const FETCH_CHAT_ROOM = ({id}) =>
axios.get(
  `${API_SERVER}/chat-topic/${id}`,
  // payload,
  chatTokenConfig()
);

export const GET_CHAT_ROOMS = () =>
axios.get(
  `${API_SERVER}/chat-topics`,
  // payload,
  chatTokenConfig()
);


export const CREATE_CHAT_ROOM = (payload) =>
axios.post(
  `${API_SERVER}/chat-topic`,
  payload,
  // tokenConfig()
  chatTokenConfig()
);

export const DELETE_CHAT_ROOM = (payload) =>
axios.delete(
  `${API_SERVER}/chat-topic/${payload.id}`,
  // tokenConfig()
  chatTokenConfig()
);

// websocket

export const WEBSOCKET_URL = ({token, name, web_chat_id}) => {
  const chatToken = localStorage.getItem("chat_token");
  return `${WS_SERVER}/ws-1/${chatToken}/${name}?web_chat_id=${web_chat_id}`
}
  
  


