import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { FaTrash } from 'react-icons/fa'; // Import the delete icon
import { AiOutlineClose } from "react-icons/ai";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";

import {
  SG_GET_CHAT_ROOM,
  SG_FETCH_CHAT_ROOM,
  SET_WEB_CHAT_ID,
  SET_WEB_CHAT_MESSAGES,
  DISCONNECT_WEBSOCKET,
  SG_DELETE_CHAT_ROOM,
  CLEAR_DELETE_MESSAGE,
  // SG_CREATE_CHAT_ROOM

} from "../constants/actions";

function ChatTopics({
  selectedChatRoom, 
  setSelectedChatRoom, 
  setTopicCreated, 
  topicCreated, 
  chatHistory, 
  setChatHistory, 
  setNewChannel}) {
  const dispatch = useDispatch();
  const [chatList, setChatList] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false)
  
useEffect(() => {
  dispatch({
    type: SG_GET_CHAT_ROOM
  });
}, [dispatch]);
// topicCreated
useEffect(() => {
  if (topicCreated) {
    const timer1 = setTimeout(() => {
      dispatch({
        type: SG_GET_CHAT_ROOM
      });
    }, 500);
    return () => clearTimeout(timer1);
  }
}, [dispatch, topicCreated]);


  const { get_chat_topics } = useSelector(
      (state) => ({
        get_chat_topics: state.chatTopics,
      }),
      shallowEqual
    );

  useEffect(() => {
      setChatList(get_chat_topics?.chat_topics)
    }, [get_chat_topics, topicCreated]);

  useEffect(() => {
      setChatHistory(get_chat_topics?.[selectedChatRoom?.id])
    },[get_chat_topics, selectedChatRoom, setChatHistory])

  useEffect(() => {
    if (chatHistory?.interaction_history?.length > 0) {
      dispatch({
        type: SET_WEB_CHAT_MESSAGES,
        payload: chatHistory?.interaction_history
      })
    }
  }, [dispatch, chatHistory])


  function ClickedOneChat(e) {
    // console.log(e)
    setSelectedChatRoom(e)
    setNewChannel(e.id)
    dispatch({
      type:SET_WEB_CHAT_ID,
      payload: {
        id: e.id,
      name: e.name}
    })
    dispatch({
      type: SG_FETCH_CHAT_ROOM,
      payload: {id: e.id}
    });
  }
// console.log(get_chat_topics)

function NewChat() {
  setChatHistory([])
  setSelectedChatRoom(null)
  setNewChannel(null)
  // DISCONNECT_WEBSOCKET
  dispatch({
    type:DISCONNECT_WEBSOCKET,
  })
}

function Deletechat() {
  setChatHistory([])
  setSelectedChatRoom(null)
  setNewChannel(null)
  // DISCONNECT_WEBSOCKET
  dispatch({
    type:DISCONNECT_WEBSOCKET,
  })
  dispatch({
    type:SG_DELETE_CHAT_ROOM,
    payload: {id: confirmDelete}
  })
  setTopicCreated(Math.random())
  setConfirmDelete(false)
  // 
}

function ConfirmToDelete(id) {
  console.log("cliked delete =================")
  setConfirmDelete(id)
}
function ResetDeleteMessage() {
  dispatch({
    type: CLEAR_DELETE_MESSAGE,
  });
}
// selectedChatRoom
  return (
  <Container>
      {get_chat_topics?.deleted && (
        <MessageHeader>
          {get_chat_topics?.deleted?.message}
          <CloseIcon onClick={ResetDeleteMessage} />
        </MessageHeader>
      )}
    <ListContainer>
    <Button onClick={NewChat}>New Chat +</Button>
      {chatList && chatList.map((chatRoom, i) => (
        <ListItem key={i} onClick={() => ClickedOneChat(chatRoom)}
        selected={selectedChatRoom?.id === chatRoom?.id}>
        {chatRoom?.id} - {chatRoom?.name} 
        <DeleteButton onClick={() => ConfirmToDelete(chatRoom?.id)}>
              <FaTrash />
            </DeleteButton>
          
        </ListItem>
      ))}
    </ListContainer>
    <ConfirmDeleteModal
          isOpen={confirmDelete}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={Deletechat}
        />
  </Container>
  );
}

export default ChatTopics;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  width: 100%;
  background-color: #dcdcdc;
  margin-bottom: 10px;
  cursor: pointer;
`;

// Styled component for the <ul> element
const ListContainer = styled.ul`
  list-style-type: none;
`;

// Styled component for the <li> element
const ListItem = styled.li`
  padding: 10px;

  background-color: ${props => props.selected ? "#eaf6ff": '#f4f4f4'};
  &:hover {
    background-color: #eaf6ff;
    cursor: pointer;
  }
`;

const MessageHeader = styled.div`
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  color: red;
`;


const DeleteButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
`;

const CloseIcon = styled(AiOutlineClose)`
  cursor: pointer;
`;