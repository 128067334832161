import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUEST,

  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  CLEAR_ALL,
  ALL_ERRORS,
} from "../constants/actions";
import * as api from "../constants/api";


// LOGIN USER
function* loginUser(action) {
  try {
    const login = yield call(api.LOGIN_USER, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: login.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
    yield put({ type: LOGIN_FAIL, payload: e });
  }
}

export function* websiteLoginUser() {
  yield takeLatest(LOGIN_REQUEST, loginUser);
}



// LOGOUT USER
function* logoutUser() {
  try {

    yield call(api.LOGOUT_USER);
    yield put({ type: CLEAR_ALL });
    yield put({ type: LOGOUT_SUCCESS });

  } catch (e) {
    yield put({ type: CLEAR_ALL });
    yield put({ type: LOGOUT_SUCCESS });

    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteLogoutUser() {
  yield takeLatest(LOGOUT_REQUEST, logoutUser);
}



export default function* index() {
  yield all([
    websiteLoginUser(),
    websiteLogoutUser(),

  ]);
}
