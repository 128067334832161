export const EMP_FEEDBACK_SMALL = `### Feedback

In having let go of a team lead, I was assured that support would be provided to help replace the team lead. Support has been offered on numerous occasions but the actual support received hasn't lived up to expectations. Filling that particular role would potentially take months, but recent weeks have been marred by pushback and a lack of clarity on the responsibilities or criteria that the candidate requires for the role to be filled.
Follow up: For the heads of the department to share a job description of the other leads in the same role from other offices in the group. Helping with interviewing the candidates earmarked for the role. 

I often get adhoc tasks randomly assigned to me with very little information attached to the ticket & with little consideration as to what work I'm currently busy with. 
These adhoc tasks are not my primary work focus, but rather additional tasks that I can see to when time opens up. 
Follow up: Provide as much detail as possible when logging the ticket, explain in simple terms the aim & overall outcome desired, how you want me to go about the task as well as when the due date is. 

I'd also like for team member to run their ideas through their managers before assigning work to me to ensure I'm not re-doing tasks that have already been done; to check with their managers if the exercise will be beneficial or add value and to make sure that I have capacity to take this task on before it is assigned

I believe that we have amazing leadership as well as leadership that needs some work.. 
3/4 of our leaders demonstrate very high-performance leadership in their behaviors - they are inspiring, when you talk to them you learn something new in almost every conversation; however the others do not.

Issues that come to mind include:

Group 'punishment',
Treating adult employees like children in school, 
Their 'prerogative'  - our leaders made a clear line between themselves and all of us who work on the floor 

Follow up: Double standards with no accountability - a leader/manager can mess up in a particular way with no accountability, but then if it is under suspicion that a lower level employee does the same thing - then a group of lower level employees around the same age get called into a meeting, sat down like children and reprimanded as a group, even if you were not present on the day of the incident. 
This in turn makes employees feel bad for using company facilities that were provided to them, and scared that any 'reward/benefit' provided in the future is almost like a trap to get into trouble again - it disinterests me from getting involved in future get togethers.

No one - except for copy writers occasionally - works from home, we are all in-office and therefore have all the opportunity to collaborate at any time

Its not issues , but it does comes and issue when things are due and things needs to be done, and when these things takes time to resolve, becomes an issue when you are know about things on the last minute and it puts myself on the back foot and also causing me to have unnecessary stress 
Follow up: To give me an appropriate time ahead of when tasks needs to be done, my rule is give me 2 weeks advance time if its a big project 

We have high quality leadership in a lot of the roles we have at SP, but their a lot of departments that lacks leadership. I feel for my colleagues that does not a leader to ask for assistances 
Follow up: The SEO's on the floor, we have different franchises 
I am always unsure regarding my role, my role is i am a global support person and i always get the feeling that SP thinks i only work for them but that not the case i work for the global team
Mistakes and unacceptable behaviour, is often overlooked or left for to long before it is addressed. This causes a lot of frustration and impedes work being completed.
Follow up: Individuals ignore deadlines, do not follow agreed upon processes and do not communicate to stakeholders what they are working on, or changes they are making.
There is very little visibility on what is being worked on. We are a young company and still learning, this will hopefully improve with time.
Follow up: Keep to what has been agreed to be worked on and inform stakeholders should the scope of the work change.

Our leaders try best to maintain a positive culture. Perhaps understood the question wrong

I haven't been at the company long enough to see any situations where this was necessary.
Follow up: See previous answer

I feel that people at the company are more collaborative and helpful rather than competitive.
Follow up: I'm not sure if this is incentivised by the company, but I do see a culture of helpfulness across all companies (and employees) in the group, and I think this helps to show new employees how it's done.

Due to different timezones, and the ticket based system of working, I think employees understand that clear communication is needed.

Our core values have not been clearly defined or communicated with employees.
We all have a certain level of work ethic and strive to be the best version of ourselves within the work place, however this may not align the core values of the organization.
Follow up: Incorporate it into the onboarding process when new recruits start.


there is a general feeling that certain individuals within the workplace are afforded more leniencies than others.
undesirable behaviours are allowed to continue without being addressed and when these concerns are brought to management's attention there is no sense of urgency in dealing with the matter, almost a laxy-daisy attitude and kind of "riding it out" and see whether it improves
Follow up: I would prefer not to as this would single certain people out which is not what my aim is.
bottom line -  everyone should be held to the same standards and expectancies

When there is change, we are informed
when there is a shift in responsibilities, we are informed
when there is cause for celebration or goodbyes, we are informed.
Nobody is left in the dark, there is open and transparent communication on all fronts

Sometimes the deadlines have been changed on priority tasks to accommodate other teams instead of ours, and we've had to change our entire sprints and priorities to suit those teams. However, it doesn't seem that we have much room to push back when we haven't been given adequate and reasonable time to achieve our tasks.
Follow up: Some of our team members only joined a month or so ago, and we had hard deadlines in place that were set by the board in 2022 that we had to achieve with limited team members and capacity.

A lot of the team's current priorities have involved collaborating with other teams, while this is important, the poor communication has led to multiple rounds of feedback (lengthy delays), misaligned briefs, and teams not working in sync to achieve the overall deadlines. 
Follow up: Be clear and transparent upfront about the tasks and priorities. Most importantly, hold teams accountable for not doing their part or missing their deadlines. Every internal deadline that is missed by other teams has a knock on effect on our team's ability to deliver good quality results on time. 

The workplace is friendly and most people seem to be open-minded and accepting of the diverse personalities in the office.

Communication isn't always clear and feels like we are playing the blame game when things don't always work out causing the issue to become bigger than it should be and resentment among some colleagues.
Follow up: If there is an issue with an induvial, communicate it directly with them what the problem is and not create a long e-mail thread and CC in higher-ups . This only escalates the issue further. 

Previous ideas mentioned were taken into consideration but no communication was made if the idea was moving forward or  not viable and only after I followed up 3 times was I told we are not going to use it without explanation to why.
Follow up: Creating Anonymous forums where individual's can share ideas free of judgement and then decide if it is worth pursuing as a group. 

I have freedom to work however I want and address issues I face the way I want to. I am not limited in my work style.

Broken communication and lack of feedback from external sister companies such as Resulta specifically has cause massive delays in projects resulting in large amounts of overtime work as deadlines do not change. 
Follow up: Timeous feedback, constructive feedback and feedback that actually addresses an issue while providing a solution instead of just being vague. Ensuring feedback is not condescending would also be an improvement. 

If someone is doing the bare minimum it takes months for it to be addressed. 
Follow up: As issues and expectations are circumstantial a case-by-case manual approach would probably work best. 

Our MD is a great example of high-quality leadership. 
The rest of the leadership team is lacking in the sense of they would prefer implementation of changes that benefit the individual (themselves) and not the company as a whole. Opinions are often biased and formulated around control and not management. 
I would not say it's judgment, however opinions on certain topics have not been clarified openly
Follow up: Well, WFH or No WFH is a good example
I think the leadership at SearchProject is great, and are highly supportive of their staff
We do not always address issues when a task is not that relevant, sometimes we do our best and then try to improve for the next time.
I think remote work has more advantages than disadvantages, but I do acknowledge that working in the same environment has certain advantages in some situations. Being in the same office as your coworkers all day may lead to sharing more about professional stuff, and learning a little bit more from each other. When you do that working remotely, it can happen anyway, only slower and at a lower degree.
Standardized solutions are easier to maintain.
Being competitive is important in REV, but we do it together. We have other core values that define us best. 

No one's here to micromanage. If you want feedback or guidance, it will be provided, but everyone at REV has responsibility for their tasks and work, and it's able to bring something to the table.
I feel we don't have clearly defined core values.
I am usually informed of changes but do not participate directly in the change, but I am given context.
I often question if I fit well since I was a kid... sometimes (not too many), I am full of self-doubt.

### End of Feedback`