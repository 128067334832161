import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
export default function HomePage() {

    const { get_auth } = useSelector(
        (state) => ({
          get_auth: state.auth,
        }),
        shallowEqual
      );

      
  return (
    <Container id="home-page">
      <Header>Welcome to Innerlogic Labs!</Header>
      
      {get_auth?.token ? <CenteredContainer>
      <Row>You are logged in click on the button to view the apps</Row>
      <Row>

        
      </Row>
        <ClickItem to={"apps"}>View Apps</ClickItem>
      </CenteredContainer>
      :
      <PageDescription>Login using the staging account to see our apps in development.</PageDescription>
      }
    </Container>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.h1`
  text-align: center;
  color: #007bff;
  font-size: 32px;
  margin-bottom: 20px;
`;

const PageDescription = styled.p`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 30px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  flex-direction: column;
  align-items: center; /* Center vertically */
//   height: 100vh; /* You can adjust the height as needed */
`;

const Row = styled.div`
  /* Your styling for each row */
  margin-bottom: 20px;
`;

// Add any additional styles or elements as needed
const ClickItem = styled(Link)`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
  }
`;
