import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
// import { AiOutlineClose } from 'react-icons/ai';

import {
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  SAGA_CONNECT_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  // SET_WEB_CHAT_ID,
  // SAGA_DISCONNECT_WEBSOCKET

} from "../constants/actions";

// import {EMP_FEEDBACK} from "../InternalData/emp_responses"
import {EMP_FEEDBACK_SMALL} from "../InternalData/emp_smaller_responses"

function WebSocketComponent({ selectedChatRoom, setSelectedChatRoom, setTopicCreated, chatHistory, newChannel, setNewChannel }) {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false)
  const [chatToken, setChatToken]= useState(false)

  const { get_web_sock, get_auth } = useSelector(
    (state) => ({
      get_web_sock: state.websock,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
      setMessages(chatHistory?.interaction_history)
  },[chatHistory])

  useEffect(() => {
    setChatToken(get_auth?.chat_token)
  },[get_auth])

  // const token = "osowcnormp"; // Replace with your actual authentication token

  console.log("get_web_sock?.error", get_web_sock?.error)
  console.log("probably not handled now => get_web_sock?.isConnected (websocket isConnected)" , get_web_sock?.isConnected? "Yes" : "No")

  useEffect(() => {
    if (!get_web_sock.ws && selectedChatRoom?.id) {
      // console.log("this ran +++++++++++++++++++++++++++")
      dispatch({
        type: SAGA_CONNECT_WEBSOCKET,
        payload: { chatToken, name: selectedChatRoom?.name,  web_chat_id: selectedChatRoom?.id},
      });
  } 
    // Reset messages when selectedChatRoom changes
    // setMessages([]);
    // eslint-disable-next-line

  }, [dispatch, get_web_sock, selectedChatRoom, chatToken]);

  useEffect(() => {
    if (newChannel > 0) {
      if (get_web_sock?.channel?.id && get_web_sock?.channel?.name) {
        // console.log("this ran +++++++++++----------------------------")
          dispatch({
            type: SAGA_CONNECT_WEBSOCKET,
            payload: { chatToken, name: get_web_sock?.channel?.name,  web_chat_id: get_web_sock?.channel?.id},
          });
      setNewChannel(0)
      }
    }
  }, [dispatch, get_web_sock, newChannel, setNewChannel, chatToken]);

  useEffect(() => {  
  setMessages(get_web_sock.message);
    if (get_web_sock.ws) {
      setLoading(get_web_sock.pending)
    } else {
      setLoading(true)
    }
    
  }, [get_web_sock])

  useEffect(() => {
    setSelectedChatRoom({
      id: get_web_sock?.data?.web_chat_id,
      name: get_web_sock?.data?.name
    });
  },[get_web_sock.data, setSelectedChatRoom])

  // console.log("chatHistory.interaction_history", chatHistory?.interaction_history  )
  // console.log("get_web_sock.message", get_web_sock.message)
  // console.log("selectedChatRoom", selectedChatRoom)
  // console.log("get_web_sock?.data", get_web_sock?.data)
  // console.log("get_web_sock?.channel", get_web_sock?.channel)

  const sendMessage = (event) => {
    event.preventDefault();

    if (get_web_sock.ws) {
      dispatch({
        type: SAGA_MESSAGE_WEBSOCKET,
        payload: {
          id: uuidv4(),
          message: inputValue,
          name: selectedChatRoom?.name,
          web_chat_id: selectedChatRoom?.id
        },
      });
    }
    else {
      const name = inputValue
        .slice(0, 50)
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[^a-zA-Z0-9_]/g, '');

      // setSelectedChatRoom(name);
      dispatch({
        type: SAGA_START_AND_MESSAGE_WEBSOCKET,
        payload: {
          chatToken,
          name,
          message: {
            id: uuidv4(),
            message: inputValue,
          },
          contextData: EMP_FEEDBACK_SMALL, // the context data base on the filtered information trunacate to match 4097 tokens
          // contextData: EMP_FEEDBACK, // the context data base on the filtered information
        },
      });
      setTopicCreated(Math.random())
    }

    setInputValue('');

  };

  // console.log(loading)

  return (
    <div>


      <MessageList>

        {messages && Object.values(messages).map((message) => {
          
          return <MessageItem key={message?.id}>
            {message?.error && <MessageContent isuser="true">
              <MessageError>Error:   {message?.error}</MessageError>
            
            </MessageContent> }
            <MessageContent isuser="true">
              <MessageSender>Me: </MessageSender>
              {message?.message}
            </MessageContent>
            {!get_web_sock?.isConnected && <ErrorHeader>Not connected</ErrorHeader> }
            <MessageContent>
              <MessageSender>Server: </MessageSender>
              {message?.server?.error && <MessageContent isuser="true">
              <MessageError>Error:   {message?.server?.error}</MessageError>
            
            </MessageContent> }
              {message?.server?.response}
            </MessageContent>
          </MessageItem>
        }

        )}

      </MessageList>
      <form onSubmit={sendMessage}>
        <input
          type="text"
          id="messageText"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <button
          disabled={selectedChatRoom?.id ? loading : false}

          type="submit">{selectedChatRoom?.id ? "Send" : "Start"}</button>
      </form>
    </div>
  );
}

export default WebSocketComponent;

const MessageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const MessageItem = styled.li`

 
  margin-bottom: 10px;
`;

const MessageContent = styled.div`
display: flex;
  background-color: ${props => (props.isuser ? '#eaf6ff' : '#f4f4f4')};
  justify-content: ${props => (props.isuser ? 'flex-start' : 'flex-end')};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const MessageSender = styled.strong`
  margin-right: 5px;
`;

const MessageError = styled.strong`
  margin-right: 5px;
  font-weight: 600;
  color: red;
`;

const ErrorHeader = styled.div`
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  color: red;

`;

// const CloseIcon = styled(AiOutlineClose)`
//   cursor: pointer;
// `;
