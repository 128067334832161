import React, { useState } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";


import { CLEAR_ERROR } from "../constants/actions";
import WebSocketComponent from "../webchat/WebSocket";
import ChatRooms from "../webchat/ChatRoom";


function MainApp() {
  const dispatch = useDispatch();
  const [selectedChatRoom, setSelectedChatRoom] = useState(false);
  const [topicCreated, setTopicCreated] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [newChannel, setNewChannel] = useState(0);

  const { get_error } = useSelector(
    (state) => ({
      get_error: state.error,
    }),
    shallowEqual
  );

  function ResetError() {
    dispatch({
      type: CLEAR_ERROR,
    });
  }

  return (
    <>
      <Header>AI Chat Demo</Header>
      {get_error?.error && (
        <ErrorHeader>
          {get_error?.error}
          <CloseIcon onClick={ResetError} />
        </ErrorHeader>
      )}

      <PageContainer>
        <LeftColumn>
          <SubHeader>Topics</SubHeader>

          <ChatRooms
            selectedChatRoom={selectedChatRoom}
            setSelectedChatRoom={setSelectedChatRoom}
            topicCreated={topicCreated}
            setTopicCreated={setTopicCreated}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            setNewChannel={setNewChannel}
          />
        </LeftColumn>
        <RightColumn>
          <SubHeader>
            {selectedChatRoom?.id
              ? `Topic ${selectedChatRoom?.id} - ${selectedChatRoom?.name}`
              : "No topic selected"}
          </SubHeader>
        

          <WebSocketComponent
            selectedChatRoom={selectedChatRoom}
            setSelectedChatRoom={setSelectedChatRoom}
            setTopicCreated={setTopicCreated}
            chatHistory={chatHistory}
            setNewChannel={setNewChannel}
            newChannel={newChannel}
          />
        </RightColumn>
      </PageContainer>
    </>
  );
}

export default MainApp;

const Header = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const SubHeader = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 5px;
`;

const ErrorHeader = styled.div`
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  color: red;
`;

const CloseIcon = styled(AiOutlineClose)`
  cursor: pointer;
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
  gap: 20px;
`;

const LeftColumn = styled.div`
  // background-color: lightgray;
`;

const RightColumn = styled.div`
  padding: 0px 10px;
`;
