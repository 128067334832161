import React from "react";
import { Routes, Route } from "react-router-dom";

import ErrorPage from '../error-pages/NotFound';

import {Menulist} from "./MenuList"
import List from "./List"


function AppList() {
    console.log("I am on apps page")
    return (
      <div style={{ marginTop: "50px" }}>
        <Routes>
          <Route path="/" element={<List Menulist={Menulist} />} />
          {Menulist.map((m, i) => {
            return <Route path={m.path} element={m.element} key={i} />;
          })}
           <Route path="*" element={<ErrorPage />} /> {/* Optional - Render a NotFound component for unmatched paths */}
        </Routes>
      </div>
    );
  }
  
  export default AppList;