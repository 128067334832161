import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";


import { SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,  UPDATE_ACCOUNT_DEFAULT_ORGANIZATION, } from "../constants/actions";

const Organization = ({ get_auth, options, currOrgId }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [orgList, setOrgList] = useState(options);
  const [selectedOrg, setSelectedOrg] = useState(false);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);


  useEffect(() => {
    setOrgList(get_auth?.organization_list);
    setEmail(get_auth?.user?.email);
  }, [get_auth]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;

    if (orgList?.length > 0) {
      if (!selectedOrg?.AccountID) {
        ErrorList["organization"] = {
          error: true,
          message: "Please select an Organization",
        };
        totalErrors += 1;
      }
    }
    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [orgList, selectedOrg, pristine]);


  useEffect(() => {
    setSelectedOrg(
      orgList.find((org) => org?.OrganizationID === currOrgId)
    );
  },[currOrgId, orgList])


  const handleSelect = (event) => {
    setSelectedOrg(
      orgList.find((org) => org?.OrganizationID === Number(event.target.value))
    );
  };

  function SubmitOrg() {

    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }

    dispatch({
      type: UPDATE_ACCOUNT_DEFAULT_ORGANIZATION,
      payload: selectedOrg?.OrganizationID
    })

    if (currOrgId !== selectedOrg?.OrganizationID) {
      dispatch({
        type: SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
        payload: {
            email: email,
            account_id: selectedOrg?.AccountID,
            employee_id: selectedOrg?.EmployeeID,
          },
        });
      }
    setPristine(true);
  }


  return (
    <>
      <LoginForm>
        <Title>Select Org</Title>
        {!pristine &&
          formErrors?.organization?.message && <ErrorMessages>{formErrors?.organization?.message}</ErrorMessages>
          }

        <Select value={selectedOrg?.OrganizationID} onChange={handleSelect}>
          <option value="">Select an option</option>
          {orgList && orgList.map((option, i) => (
            <option key={i} value={option?.OrganizationID}>
              {option?.OrganizationID} - {option?.Name}
            </option>
          ))}
        </Select>
        <LoginButton onClick={SubmitOrg}>Select</LoginButton>
        {selectedOrg && (
          <SelectedValue>Selected Value: {selectedOrg?.Name}</SelectedValue>
        )}
      </LoginForm>
    </>
  );
};

export default Organization;

const Select = styled.select`
  padding: 8px;
  font-size: 16px;
  margin: 10px;
`;

const SelectedValue = styled.p`
  font-size: 18px;
  margin: 10px;
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessages = styled.p`
  color: #ff0000; /* Red color for error messages */
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
`;