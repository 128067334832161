import React from 'react';
import { Provider } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

import store from "./store";

import AppList from './AppList';
import ErrorPage from './error-pages/NotFound';

import HomeLayout from "./layouts/Home"
import AuthLayout from "./layouts/Auth"

import HomePage from "./Home/index"
import Login from "./Auth/Login"
import Logout from "./Auth/Logout"

import List from "./AppList/List"

const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            
            {
              path: "",
              element: <HomePage />,
             
            },
            {
              path: "login",
              element: <Login />,
             
            },
            {
              path: "logout",
              element: <Logout />,
             
            },
            /* the rest of the routes */
          ],
    }]
  },
    {
      path: "/apps",
      element: <AuthLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          errorElement: <ErrorPage />,
          element: <List />,
          
        },
        {
          path: "*",
          element: <AppList />, // This handles the /apps/webchat route
        },
      ],
    },
  ]);



  function App() {
    return (
      <Provider store={store}>
        <RouterProvider router={router}>
        {/* Render the content */}
        {router}
      </RouterProvider>
      </Provider>
    );
  }
  
  export default App;
  