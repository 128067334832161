import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
// import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { CLEAR_ALL, LOGOUT_REQUEST, CLEAR_ERRORS } from "../constants/actions";



const Logout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();


  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual
  );

  const [success] = useState("Logged Out Sucessfully, taking you to website");

  useEffect(() => {
    // console.log(get_auth?.token);
    if (get_auth?.token) {
      // console.log("Logged out")
      dispatch({ type: LOGOUT_REQUEST });
    }
    dispatch({ type: CLEAR_ALL });
    dispatch({
      type: CLEAR_ERRORS,
    });


  }, [dispatch, get_auth]);

  useEffect(() => {
   
      // use when testing to make sure you dont have to 
      // navigate back each time
      setTimeout(
        () => navigate("/"),
        2000
      );
    
  })

  return (
    <div style={{ height: '100%', paddingTop: '25vh' }}>
     

      <div style={{
        height: '50%', width: '50%',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}>
        {success && (
          <>
         
            <Message>{success}</Message>
            
          </>
        )}

      </div>


      {/* <LoginForm message={"Logged Out Sucessfully"}  /> */}

    </div>
  );
};

export default Logout;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
  background-color: #f0f0f0;
`;