import React, { useState } from "react";
import styled from "styled-components";
import { Outlet, Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

const ResponsiveMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const MenuList = [
    { name: "Home", link: "/", private: true },
    { name: "Apps", link: "/apps", private: true },
    // { name: "Login", link: "/login", private: false },
    { name: "Logout", link: "/logout", private: true },
  ];

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual
  );

  return (
    <Container>
      <MenuContainer>
        {/* Desktop Menu */}
        <DesktopMenu>
          <ul>
            {MenuList.map((me, i) => {
              if (get_auth?.token) {
                if (me?.private) {
                  return (
                    <MenuItem to={me.link} key={i}>
                      {me.name}
                    </MenuItem>
                  );
                }
              } else {
                if (!me?.private) {
                  return (
                    <MenuItem to={me.link} key={i}>
                      {me.name}
                    </MenuItem>
                  );
                }
              }
              return null;
            })}
          </ul>
        </DesktopMenu>

        {/* Mobile Menu */}
        <MobileMenu>
          <button onClick={toggleMenu}>{menuOpen ? "Close" : "Menu"}</button>
          {menuOpen && (
            <ul>
              {MenuList.map((me, i) => {
                if (get_auth?.token) {
                }
                return <MenuItem key={i}>{me.name}</MenuItem>;
              })}
            </ul>
          )}
        </MobileMenu>
      </MenuContainer>

      {/* Outlet for rendering the content based on the routes */}
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </Container>
  );
};

export default ResponsiveMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #f0f0f0;
  padding: 10px;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const DesktopMenu = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
  }

  max-width: 1200px; /* Set the maximum width */
  // margin: 0 auto; /* Center the container horizontally */

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;

    button {
      display: block;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const MenuItem = styled(Link)`
  /* Add your styles for the menu items here */
  margin-right: 15px; /* Adjust spacing between items */
  cursor: pointer; /* Change cursor to link pointer */
  /* Add any additional styles here */
`;

const OutletContainer = styled.div`
  /* Add your styles for the Outlet container here */
  flex: 1 0 auto;
  //  /* Adjust as needed */
  background-color: #ffffff; /* Adjust as needed */
  // padding: 10px; /* Adjust as needed */
  // border: 1px solid #cccccc; /* Adjust as needed */
`;
