import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Menulist } from "./MenuList";

function AppDescription() {
  const [selectedApp, setSelectedApp] = useState(null);

  const handleAppClick = (app) => {
    setSelectedApp(app);
  };

  return (
    <Container>
      <LeftMenu>
        {Menulist.map((m, i) => (
          <MenuItem
            key={i}
            onClick={() => handleAppClick(m)}
            style={{
              fontWeight: selectedApp?.name === m.name ? "bold" : "normal",
            }}
          >
            {m?.name}
          </MenuItem>
        ))}
      </LeftMenu>
      <RightContent>
        {selectedApp && (
          <>
            <h2>{selectedApp?.title}</h2>
            <p>
              {/* Use React.Fragment to display multiple lines */}
              {selectedApp.description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <ClickItem to={selectedApp.url}>View App</ClickItem>
          </>
        )}
      </RightContent>
    </Container>
  );
}

export default AppDescription;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
`;

const LeftMenu = styled.div`
  width: 30%;
  padding: 20px;
  background-color: #f1f1f1;
`;

const RightContent = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
`;
const MenuItem = styled.div`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
  }
`;


const ClickItem = styled(Link)`
//   display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
  }
`;
